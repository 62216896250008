.root {
}

.bar {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  z-index: 40;

  border-radius: 6px;
  backdrop-filter: blur(4px);
}

.button {
  display: flex;
  gap: 5px;
  align-items: center;

  font-size: 24px;
  color: var(--color-white);
  text-decoration: none;
}

.button:hover,
.button:focus {
  text-decoration: underline;
}

.open .button {
  display: none;
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 40px;

  transition: 0.3s ease-in-out transform;
  transform: translateX(100%);

  z-index: 45;
}

.open .drawer {
  transform: translateX(0);
}

.header {
  display: flex;
  flex-direction: row-reverse;
  align-items: right;
}

/* Hide nav title */
.title {
  clip: 0 0 0 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
}

.close {
  display: block;
  z-index: 1000;
  margin: 0 0 20px;
  transform: rotate(180deg);
  background-color: #010101;
  mask: url(/images/icons/back.svg) 0 0/48px 48px;
  width: 48px;
  height: 48px;
  color: transparent;
}

.close:hover,
.close:focus {
  background-color: var(--color-red);
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-size: 24px;
}

.link {
  color: #010101;
  text-decoration: none;
  cursor: pointer;
}
.link:hover,
.link:focus {
  text-decoration: underline;
}

.current {
  /* font-weight: bold; */
  color: var(--color-red);
}
